<template>
  <div>
    <!-- The header buttons -->
    <div class="d-flex justify-space-between">
      <!-- Show the go back button -->
      <v-btn
        text
        color="primary"
        @click="$router.push({ name: 'InfluencerOutreachDetail', params: { uuid: $route.params.uuid } })"
      >
        <v-icon left>
          arrow_back
        </v-icon>

        Influencers
      </v-btn>
    </div>

    <!-- Divide the page into two sections -->
    <v-row class="mt-8">
      <!-- Left section for form -->
      <v-col
        class="px-0 py-0"
        cols="12"
        md="4"
        lg="3"
      >
        <div class="white px-3 py-3 rounded">
          <!-- The header section with user card -->
          <div class="d-flex align-end">
            <user-avatar
              size="90"
              :src="influencer.preview ? influencer.preview.picture : null"
              :name="influencer.preview ? influencer.preview.fullname : influencer.username"
            />

            <!-- Show the name -->
            <div class="platform-cta text-left mx-auto mt-4">
              <v-list-item
                ripple
                @click="redirectToWeb"
              >
                <v-list-item-action class="mr-3">
                  <v-img
                    height="36"
                    width="36"
                    :src="platformIconMap[influencer.platform]"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="font-weight-medium platform-name text-capitalize">
                      {{ influencer.platform }}
                    </div>
                    <div class="text-gray-custom text-caption">
                      {{ influencer.username }}
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>

          <!-- A divider in between -->
          <v-divider class="mt-6 mb-6" />

          <!-- First Name input field -->
          <v-text-field
            v-model="formData.firstName"
            :hide-details="!$v.formData.firstName.$anyError"
            :error-messages="$v.formData.firstName.$anyError ? ['Please enter the name under 200 characters'] : null"
            @blur="$v.formData.firstName.$touch"
            :disabled="isMakingRequest"
            label="First Name"
            counter="200"
            class="mb-4"
            outlined
            dense
          />

          <!-- Last Name input field -->
          <v-text-field
            v-model="formData.lastName"
            :hide-details="!$v.formData.lastName.$anyError"
            :error-messages="$v.formData.lastName.$anyError ? ['Please enter the name under 200 characters'] : null"
            @blur="$v.formData.lastName.$touch"
            :disabled="isMakingRequest"
            label="Last Name"
            counter="200"
            class="mb-4"
            outlined
            dense
          />

          <!-- Email input field -->
          <v-text-field
            v-model="formData.email"
            :hide-details="!$v.formData.email.$anyError"
            :error-messages="$v.formData.email.$anyError ? ['Please enter a valid email under 200 characters'] : null"
            @blur="$v.formData.email.$touch"
            :disabled="isMakingRequest"
            label="Email Address"
            counter="200"
            class="mb-4"
            outlined
            dense
          />

          <!-- Phone input field -->
          <v-text-field
            v-model="formData.phone"
            :hide-details="!$v.formData.phone.$anyError"
            :error-messages="$v.formData.phone.$anyError ? ['Please enter phone number under 200 characters'] : null"
            @blur="$v.formData.phone.$touch"
            :disabled="isMakingRequest"
            label="Phone Number"
            counter="200"
            class="mb-4"
            outlined
            dense
          />

          <!-- Status select field -->
          <v-select
            v-model="formData.status"
            :items="statusOptions"
            :hide-details="!$v.formData.status.$anyError"
            :error-messages="$v.formData.status.$anyError ? ['Please select a status'] : null"
            @blur="$v.formData.status.$touch"
            :disabled="isMakingRequest"
            label="Status"
            class="mb-4"
            outlined
            dense
          />

          <!-- Show the submit button -->
          <v-btn
            block
            depressed
            color="primary"
            :disabled="isMakingRequest"
            :loading="isMakingRequest"
            @click="updateInfluencer"
          >
            Save

            <v-icon right>
              upgrade
            </v-icon>
          </v-btn>
        </div>
      </v-col>

      <!-- Right section for other interactive modules -->
      <v-col
        cols="12"
        md="8"
        lg="9"
      >
        <v-tabs
          background-color="transparent"
          v-model="tabNavigation"
          @change="handleTabChange"
          color="primary"
          show-arrows
          centered
          grow
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-notes">
            <v-icon left>text_snippet</v-icon>

            Notes
          </v-tab>

          <v-tab href="#tab-emails">
            <v-icon left>alternate_email</v-icon>

            Emails
          </v-tab>

          <v-tab href="#tab-prices">
            <v-icon left>sell</v-icon>

            Prices
          </v-tab>

          <v-tab href="#tab-tasks">
            <v-icon left>task_alt</v-icon>

            Tasks
          </v-tab>

          <v-tab href="#tab-posts">
            <v-icon left>image</v-icon>

            Posts
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabNavigation">
          <v-tab-item value="tab-notes">
            <v-card flat color="accent">
              <v-card-text class="px-0" :class="{ 'black--text': isThemeLight }">
                <influencer-notes :data="data" :influencer="influencer" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="tab-emails">
            <v-card flat color="accent">
              <v-card-text class="px-0" :class="{ 'black--text': isThemeLight }">
                <influencer-emails :data="data" :influencer="influencer" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="tab-prices">
            <v-card flat color="accent">
              <v-card-text class="px-0" :class="{ 'black--text': isThemeLight }">
                <influencer-prices :data="data" :influencer="influencer" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="tab-tasks">
            <v-card flat color="accent">
              <v-card-text class="px-0" :class="{ 'black--text': isThemeLight }">
                <influencer-tasks :data="data" :influencer="influencer" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="tab-posts">
            <v-card flat color="accent">
              <v-card-text class="px-0" :class="{ 'black--text': isThemeLight }">
                <influencer-posts :data="data" :influencer="influencer" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Import children components
const UserAvatar = () => import(/* webpackChunkName: "user-avatar" */ "@/blocks/common/UserAvatar.vue")
const InfluencerNotes = () => import(/* webpackChunkName: "influencer-outreach-notes" */ "@/components/crm/influencer-outreach/InfluencerNotes.vue")
const InfluencerTasks = () => import(/* webpackChunkName: "influencer-outreach-tasks" */ "@/components/crm/influencer-outreach/InfluencerTasks.vue")
const InfluencerPosts = () => import(/* webpackChunkName: "influencer-outreach-posts" */ "@/components/crm/influencer-outreach/InfluencerPosts.vue")
const InfluencerEmails = () => import(/* webpackChunkName: "influencer-outreach-emails" */ "@/components/crm/influencer-outreach/InfluencerEmails.vue")
const InfluencerPrices = () => import(/* webpackChunkName: "influencer-outreach-prices" */ "@/components/crm/influencer-outreach/InfluencerPrices.vue")

// Import helper functions
import { required, maxLength, email } from "vuelidate/lib/validators"

// Define generator function
const generateForm = () => ({
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  status: "pending"
})

// Export the SFC
export default {
  // Name of the component
  name: "InfluencerOutreachInfluencer",

  // Register the children component
  components: {
    UserAvatar,
    InfluencerNotes,
    InfluencerTasks,
    InfluencerPosts,
    InfluencerEmails,
    InfluencerPrices,
  },

  // Accept incoming data from parent
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    // Whether or not is the app making a network request
    isMakingRequest: false,

    // The form object for input values
    formData: generateForm(),

    // The current active tab
    tabNavigation: null,

    // Possible status options
    statusOptions: [
      {
        text: "Pending",
        value: "pending",
      },
      {
        text: "In Progress",
        value: "in-progress",
      },
      {
        text: "Cancelled",
        value: "cancelled",
      },
      {
        text: "Completed",
        value: "completed",
      },
    ]
  }),

  // Define readonly computable variables
  computed: {
    /**
     * Get the influencer data from the prop data
     *
     * @returns {Null|Object}
     */
    influencer() {
      return this.data.influencers.find((search) => search.id === parseInt(this.$route.params.influencerId))
    }
  },

  // Define vuelidate validations object
  validations: {
    formData: {
      firstName: {
        maxLength: maxLength(200),
      },
      lastName: {
        maxLength: maxLength(200)
      },
      email: {
        email,
        maxLength: maxLength(200)
      },
      phone: {
        maxLength: maxLength(200)
      },
      status: {
        required
      }
    }
  },

  // Define local method functions
  methods: {
    /**
     * Build an URL and redirect the user to it
     *
     * @returns {void}
     */
    redirectToWeb() {
      const map = {
        tiktok: "https://tiktok.com/@",
        instagram: "https://instagram.com/",
        youtube: "https://youtube.com/channel/"
      }

      window.redirectToWeb(`${map[this.influencer.platform]}${this.influencer.platform === 'youtube' ? (this.influencer.preview?.user_id || this.influencer.preview?.username) : this.influencer.username}`)
    },

    /**
     * Make network request to update influencer data
     *
     * @returns {void}
     */
    async updateInfluencer() {
      // Run validations
      await this.$v.formData.$touch()

      // If there's any error
      if (this.$v.formData.$anyError) {
        // Stop further execution
        return
      }

      // Otherwise, add a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Make the network request
      try {
        // Use the helper function
        await axios({
          url: `/api/influencer-outreach/${this.data.model.id}/influencers/${this.influencer.id}`,
          method: "PUT",
          data: {
            first_name: this.formData.firstName,
            last_name: this.formData.lastName,
            phone: this.formData.phone,
            email: this.formData.email,
            status: this.formData.status
          }
        })

        // Show a toast message
        this.$store.dispatch("toasts/add", { text: "Influencer details updated!" })

        // Also, dispatch an action to update this data in local Vuex store
        this.$store.dispatch("influencerOutreach/updateInfluencer", {
          id: this.influencer.id,
          data: this.formData,
          campaignId: this.data.model.id
        })
      }
      // Catch errors
      catch (error) {
        // Log into console
        logger({ type: "InfluencerOutreach/Influencers Update Error", error })

        // Show an error message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred!" })
      }
      // Nevertheless
      finally {
        // Hide the loaders
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
      }
    },

    /**
     * Handle the tab change event
     *
     * @returns {void}
     */
    handleTabChange(tab) {
      // If the query params are different
      if (this.$route.query.tab !== tab) {
        this.$router.replace({
          name: this.$route.name,
          params: this.$route.params,
          query: { tab }
        })
      }
    }
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // If the data is invalid, redirect to 404
    if (!this.influencer) {
      this.$router.replace("/404")
    }
    // Otherwise
    else {
      // Update the default form data values
      this.formData = {
        firstName: this.influencer.first_name,
        lastName: this.influencer.last_name,
        email: this.influencer.email,
        phone: this.influencer.phone,
        status: this.influencer.status
      }
    }

    // Now, if we have a query param value already, let's use that
    if (this.$route.query.tab) {
      this.tabNavigation = this.$route.query.tab
    }
  }
}
</script>
